import React, { useState } from "react";
import ImgTag from "../../components/Elements/ImgTag";
import {
  appDevelopmentSourceURL,
  businessGrowthSourceURL,
  digitalMarketingSourceURL,
  graphicDesignSourceURL,
  hostingSupportSourceURL,
  seoSourceURL,
  serviceNewsLetterSourceURL,
  serviceWhyUsSourceURL,
  technicalSupportSourceURL,
  webDesigningSourceURL,
  webDevelopmentSourceURL,
  websiteMaintenanceSourceURL,
} from "../../assets/js/Constants/localImageURL";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import axios from "axios";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

function ServicesPage() {
  const { service, lang } = useParams();
  const [emailAddress, setEmailAddress] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/emailSubscribe-post", {
        emailAddress,
      });
      console.log("response", response);
      if (response.data === "Message received") {
        toast.success(
          "Thank you for subscribing! You've successfully joined our email list. Stay tuned for updates!"
        );
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(`Sorry. Facing Error: ${error.message}`);
    }
  };

  const addActiveClass = (serviceDiv) =>
    service === serviceDiv ? "ss_flip_card-inner active" : "ss_flip_card-inner";
  return (
    <>
      {/* Breadcrumb Section Start */}
      <Breadcrumb title={t("Services")} />
      {/* Breadcrumb Section End */}
      <section id="ss-service_page" className="ss-service_page">
        <div className="service_page">
          <div className="container">
            <div className="main_content">
              <h2 className="sam_title">{t("What_Can_We_Offer")}</h2>
              <h3 className="sam_sub_title">
                {t("We_offer_IT_services_to_help_your_business_expand")}
              </h3>
            </div>
            <div className="row services_row" lang={lang}>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className={addActiveClass("web-development")}>
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={webDevelopmentSourceURL}
                            altText="Web Development"
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Web_Development")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Our_web_development_service_offers_customized_needs_and_goals"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className={addActiveClass("app-development")}>
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={appDevelopmentSourceURL}
                            altText=""
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("App_Development")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Our_app_development_service_creates_user-friendly__across_all_platforms"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className={addActiveClass("web-designing")}>
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={webDesigningSourceURL}
                            altText="Web Development"
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Web_Designing")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Our_web_design_service_focuses_on_creating__enhance_user_experience"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className={addActiveClass("graphic-designing")}>
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={graphicDesignSourceURL}
                            altText=""
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Graphic_Designing")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Our_graphic_design_service_crafts_compelling_visuals_that__audience_and_elevate_your_brand’s_identity"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className={addActiveClass("technical-support")}>
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={technicalSupportSourceURL}
                            altText=""
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Technical_Support")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Our_technical_support_service_ensures_your_systems__minimizing_downtime"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className={addActiveClass("website-maintenance")}>
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={websiteMaintenanceSourceURL}
                            altText=""
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Website_Maintenance")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Our_website_maintenance_service_keeps_your_site_running__user_experience"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className={addActiveClass("hosting-support")}>
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={hostingSupportSourceURL}
                            altText=""
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Hosting_Support")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Our_hosting_support_service_provides_reliable__and_fully_operational_at_all_times"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className={addActiveClass("seo")}>
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag sourceURL={seoSourceURL} altText="Seo" />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("SEO")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Our_SEO_service_boosts_your_online_visibility_with_targeted__enhancing_its_reach_and_impact"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className={addActiveClass("digital-marketing")}>
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={digitalMarketingSourceURL}
                            altText="Digital Marketing"
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Digital_Marketing")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Our_digital_marketing_service_creates_tailored_strategies_to_amplify__and_other_digital_channels"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service_col">
                <div className="ss_flip_card" tabIndex={0}>
                  <div className={addActiveClass("business-growth")}>
                    <div className="ss_flip_card-normal">
                      <div className="ss_content">
                        <div className="ss_icon">
                          <ImgTag
                            sourceURL={businessGrowthSourceURL}
                            altText="Business Growth"
                          />
                        </div>
                        <div className="ss_title">
                          <h2 className="title">{t("Business_Growth")}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="ss_flip_card-hover">
                      <div className="ss_content">
                        <div className="ss_text">
                          <p className="text">
                            {t(
                              "Our_business_growth_service_offers_strategic_guidance_and_innovative__potential_in_competitive_markets"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service Page First Section End */}
      <section id="ss-why-us" className="why_choose_us">
        <div className="why-us_body">
          <div className="container">
            <div className="sec_title">
              <h2 className="sam_title text-center">{t("Why_Choose_Us")}</h2>
            </div>
            <div className="row why-us_row">
              <div className="img_col">
                <div className="img">
                  <ImgTag sourceURL={serviceWhyUsSourceURL} altText="" />
                </div>
              </div>
              <div className="content_col">
                <div className="content">
                  <ul className="content_list">
                    <li className="box">
                      <h2 className="title">{t("Understand_Your_Needs")}</h2>
                      <p className="text">
                        {t(
                          "We_listen_carefully_to_your_requirements__business_needs"
                        )}
                      </p>
                    </li>
                    <li className="box">
                      <h2 className="title">{t("Develop_and_Design")}</h2>
                      <p className="text">
                        {t("Our_team_builds_and_designs_your__performance")}
                      </p>
                    </li>
                    <li className="box">
                      <h2 className="title">{t("Deliver_and_Support")}</h2>
                      <p className="text">
                        {t("We_launch_your_solution__running_perfectly")}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service Page First Section End */}
      <section id="ss-newsletter" className="newsletter_sec">
        <div className="container">
          <div className="newsletter_body">
            <div className="row newletter_row">
              <div className="content_col">
                <div className="content_box">
                  <ImgTag sourceURL={serviceNewsLetterSourceURL} altText="" />
                  <div className="content">
                    <h3 className="title">
                      {t("Subscribe_Now_To_Get_Latest_update")}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="form_col">
                <form onSubmit={handleSubmit}>
                  <div className="form_field">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder={t("Email_Address")}
                      required
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                    <div className="submint_btn">
                      <button type="submit">
                        Send <i className="fa-solid fa-paper-plane" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServicesPage;
