import React, { useState } from "react";
import axios from "axios";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

function DataPage() {
  const [contactUsData, setContactUsData] = useState([]);
  const [emailSubscribeData, setEmailSubscribeData] = useState([]);
  const [verificationCode, setVerificationCode] = useState("");
  const [errMsg, setErrMsg] = useState(false);
  const fetchData = async () => {
    try {
      const contactUsResponse = await axios.get("/api/contactUs-get");
      const emailSubscribeResponse = await axios.get("/api/emailSubscribe-get");
      setContactUsData(contactUsResponse.data);
      setEmailSubscribeData(emailSubscribeResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleVerification = () => {
    const correctCode = "070319";
    if (correctCode === verificationCode) {
      setErrMsg(false);
      fetchData();
    } else {
      setErrMsg(true);
    }
  };
  return (
    <>
    {/* Breadcrumb Section Start */}
    <Breadcrumb title={"Data Page"} />
      {/* Breadcrumb Section End */}
      <button
        type="button"
        className="btn btn-primary btn-lg m-5 col-8 col-md-11"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-bs-whatever="@mdo"
      >
        Verify
      </button>
      <p className={`text-danger m-4 m-md-5 ${errMsg ? "d-block" : "d-none"}`}>
        Verification Code does not Match, Please try again!
      </p>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Verification Model
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="recipient-name" className="col-htmlForm-label">
                  Enter a Secret Code:
                </label>
                <input
                  type="password"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  className="htmlForm-control"
                  id="recipient-name"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={handleVerification}
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
      <h3>Contact Us data</h3>
      <div className="overflow-x-scroll">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Subject</th>
              <th scope="col">Message</th>
            </tr>
          </thead>
          <tbody>
            {contactUsData.length > 0 ? (
              contactUsData.map((value, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{value.id}</th>
                    <td>{value.name}</td>
                    <td>{value.email}</td>
                    <td>{value.phoneNumber}</td>
                    <td>{value.subject}</td>
                    <td>{value.message}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <th scope="row" colSpan={6} className="text-center">
                  No Contacts Data Available
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <hr></hr>
      <h3>Email Subscribe data:</h3>
      <div className="overflow-x-scroll">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Email Address</th>
            </tr>
          </thead>
          <tbody>
            {emailSubscribeData.length > 0 ? (
              emailSubscribeData.map((value, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{value.id}</th>
                    <td>{value.emailAddress}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <th scope="row" colSpan={6} className="text-center">
                  No Email Address Data Available
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DataPage;
