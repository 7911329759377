import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { t } from "i18next";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  MOBILENUMBER,
  OFFICIALEMAILID,
} from "../../assets/js/Constants/constants";

function ContactUsPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/contactUs-post", {
        name,
        email,
        phoneNumber,
        subject,
        message,
      });
      if (response.data == "Message received") {
        toast.success("Contact Details Send. Thank You!!!");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error(`Sorry. Facing Error: ${error.message}`);
    }
  };

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Breadcrumb title={t("Contact_Us")} />
      {/* Breadcrumb Section End */}
      <section id="contact_page">
        <div className="contact-us_page">
          <div className="container">
            <div className="ss_row row">
              <div className="col-lg-4 col-md-6 col-sm-12 call_col">
                <div className="contact-box">
                  <div className="contact_icon">
                    <i className="fa-solid fa-phone-volume" />
                  </div>
                  <h4 className="contact_title">{t("Call_Us_On")}</h4>
                  <div className="contact_content">
                    <a href="tel:7410504432">{MOBILENUMBER}</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mail_col">
                <div className="contact-box">
                  <div className="contact_icon">
                    <i className="fa-solid fa-envelope" />
                  </div>
                  <h4 className="contact_title">{t("Email_Us")}</h4>
                  <div className="contact_content">
                    <a href="mailto:thesamsoft.official@gmail.com">
                      {OFFICIALEMAILID}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 address_col">
                <div className="contact-box">
                  <div className="contact_icon">
                    <i className="fa-solid fa-location-dot" />
                  </div>
                  <h4 className="contact_title">{t("Our_Location")}</h4>
                  <div className="contact_content">
                    <a href="#">{t("Nagpur_Maharashtra")}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Page First Section End */}
      <section id="ss-contact_page-form">
        <div className="contact-form">
          <div className="container">
            <div className="row form_row">
              <div className="col-lg-5 col-md-12 social_info_col">
                <div className="section-title-wrapper">
                  <h2 className="sec_title">{t("Send_us_a_message")}</h2>
                  <div className="contact-social">
                    <span className="contact-social-title">
                      {t("Follow_Us_here")}
                    </span>
                    <ul className="contact-info-social">
                      <li className="icon">
                        <a
                          href="https://www.instagram.com/thesamsoft.official/"
                          target="_blank"
                        >
                          <i className="fa-brands fa-instagram" />
                        </a>
                      </li>
                      <li className="icon">
                        <a
                          href="https://www.facebook.com/people/Thesamsoftofficial/61560934600014/"
                          target="_blank"
                        >
                          <i className="fa-brands fa-facebook-f" />
                        </a>
                      </li>
                      <li className="icon">
                        <a
                          href="https://youtube.com/@thesamsoft3?si=yofzk9fVF3Urw0Nx"
                          target="_blank"
                        >
                          <i className="fa-brands fa-youtube" />
                        </a>
                      </li>
                      <li className="icon">
                        <a
                          href="https://www.linkedin.com/company/103322352/admin/analytics/visitors/"
                          target="_blank"
                        >
                          <i className="fa-brands fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 form_col">
                <div className="contact_form">
                  <form onSubmit={handleSubmit}>
                    <div className="form_field">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={t("Your_Name")}
                        required
                      />
                      <i className="fa-solid fa-user" />
                    </div>
                    <div className="form_field">
                      <i className="fa-solid fa-envelope" />
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t("Your_Email")}
                        required
                      />
                    </div>
                    <div className="form_field">
                      <i className="fa-solid fa-phone" />
                      <input
                        type="text"
                        id="phone_number"
                        name="phone_number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder={t("Phone_No")}
                        required
                      />
                    </div>
                    <div className="form_field">
                      <i className="fa-solid fa-book" />
                      <input
                        type="text"
                        id="subject"
                        name="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        placeholder={t("Subject")}
                        required
                      />
                    </div>
                    <div className="form__msg_field">
                      <i className="fa-solid fa-pen" />
                      <textarea
                        id="message"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder={t("Your_Message")}
                        required
                      />
                    </div>
                    <button type="submit">{t("Send_Message")}</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Page Form Section End */}
      <section id="ss-contact_page_map" className="ss-contact_page_map">
        <div className="contact_map">
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119065.02905823676!2d78.99010825797474!3d21.161065901994068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c0a5a31faf13%3A0x19b37d06d0bb3e2b!2sNagpur%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1722072851891!5m2!1sen!2sin"
              width="100%"
              height="500px"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </section>
      {/* Contact Page Map Section End */}
    </>
  );
}

export default ContactUsPage;
